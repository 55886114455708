import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';

import { stickAndPlayWheelId } from '../..';
import { StyledArrow, StyledCircle } from './style';

const ClickHereCircle = ({ gameAnimationType }: { gameAnimationType: string }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const bgColor = gameAnimationType === stickAndPlayWheelId ? '#000' : '#B50000';
  const arrowColor = gameAnimationType === stickAndPlayWheelId ? '#e6007e' : '#B50000';

  return (
    <Fragment>
      <StyledCircle $isMobile={isMobile} $bgColor={bgColor}>
        <strong>
          {t('click')} <br /> {t('here')}
        </strong>
      </StyledCircle>
      <StyledArrow
        $isMobile={isMobile}
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="47"
        viewBox="0 0 38 47"
        fill="none"
        transform="scale(-1 1)"
      >
        <path
          d="M0.52193 41.1696C0.33945 42.5382 1.30099 43.7956 2.66959 43.9781L24.9722 46.9518C26.3408 47.1342 27.5982 46.1727 27.7807 44.8041C27.9632 43.4355 27.0016 42.1781 25.633 41.9956L5.80848 39.3523L8.45175 19.5278C8.63423 18.1592 7.67269 16.9018 6.30409 16.7193C4.93549 16.5368 3.67809 17.4984 3.49561 18.867L0.52193 41.1696ZM33 0C33 5.84187 29.6068 12.3893 23.7197 19.3189C17.8894 26.1816 9.92713 33.0556 1.48137 39.5141L4.51863 43.4859C13.0729 36.9444 21.3606 29.8184 27.5303 22.5561C33.6432 15.3607 38 7.65813 38 0H33Z"
          fill={arrowColor}
        />
      </StyledArrow>
    </Fragment>
  );
};

export default ClickHereCircle;
