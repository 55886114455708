import { styled } from 'styled-components';

export const StyledCircle = styled.div<{ $isMobile?: boolean; $bgColor?: string }>`
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : '#b50000')};
  color: white;
  border-radius: 50%;
  width: ${({ $isMobile }) => ($isMobile ? '70px' : '100px')};
  height: ${({ $isMobile }) => ($isMobile ? '70px' : '100px')};
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-right: ${({ $isMobile }) => ($isMobile ? '256px' : '500px')};
  margin-bottom: -100px;
`;

export const StyledArrow = styled.svg<{ $isMobile: boolean }>`
  margin-right: ${({ $isMobile }) => ($isMobile ? '164px' : '382px')};
  margin-bottom: ${({ $isMobile }) => ($isMobile ? '-40px' : '-63px')};
  margin-top: ${({ $isMobile }) => ($isMobile ? '64px' : '55px')};
  transform: scaleX(-1);
`;
